$(function() {
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "progressBar": true,
        "positionClass": "toast-top-right",
        "onclick": null,
        "showDuration": "400",
        "hideDuration": "1000",
        "timeOut": "2000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

    $('.editor').redactor({
        lang: 'en',
        wym: true,
        focus: false,
        convertDivs: false,
        tidyHtml: false,
        maxHeight: 500,
        convertVideoLinks: true,
        Token:$('meta[name="_token"]').attr('content'),
        imageUpload: '/admin/image/upload',
        buttons: ['html', 'formatting', 'bold', 'italic', 'unorderedlist', 'orderedlist',
            'image', 'video', 'table', 'link'],
        formattingTags: ['div', 'span', 'p', 'blockquote', 'h1', 'h2', 'h3', 'h4'],
        plugins: ['preview','to_top'],
        initCallback: function() {},
        imageUploadCallback: function(image, json) { console.log(image, json); },
        imageUploadErrorCallback: function(json) { console.log(json); }
    });

    $('.editor_text').redactor({
        lang: 'en',
        wym: true,
        focus: false,
        convertDivs: false,
        tidyHtml: false,
        maxHeight: 350,
        convertVideoLinks: true,
        Token:$('meta[name="_token"]').attr('content'),
        imageUpload: '/admin/image/upload',
        formattingTags: ['div', 'span', 'p', 'blockquote', 'h2', 'h3', 'h4'],
        buttons: ['html', 'formatting', 'bold', 'italic', 'deleted', 'link', 'image', 'video'],
        initCallback: function() {},
        imageUploadCallback: function(image, json) { console.log(image, json); },
        imageUploadErrorCallback: function(json) { console.log(json); }
    });

    $('.editor_short').redactor({
        lang: 'en',
        wym: true,
        focus: false,
        convertDivs: false,
        tidyHtml: false,
        maxHeight: 250,
        convertVideoLinks: true,
        Token:$('meta[name="_token"]').attr('content'),
        imageUpload: '/admin/image/upload',
        formattingTags: ['div', 'span', 'p', 'blockquote'],
        buttons: ['html', 'bold', 'italic', 'deleted', 'link', 'image', 'video', 'unorderedlist'],
        initCallback: function() {},
        imageUploadCallback: function(image, json) { console.log(image, json); },
        imageUploadErrorCallback: function(json) { alert('!!!');console.log(json); }
    });

    $('.editor_only_ol').redactor({
        lang: 'en',
        wym: true,
        focus: false,
        convertDivs: false,
        tidyHtml: false,
        maxHeight: 250,
        formattingTags: ['ol', 'li', 'a'],
        buttons: ['orderedlist'],
        initCallback: function() {}
    });

    $('.editor_only_ul').redactor({
        lang: 'en',
        wym: true,
        focus: false,
        convertDivs: false,
        tidyHtml: false,
        maxHeight: 250,
        formattingTags: ['ul', 'li', 'a'],
        buttons: ['unorderedlist'],
        initCallback: function() {}
    });


    $('.editor_text_min').redactor({
        lang: 'en',
        wym: true,
        focus: false,
        convertDivs: false,
        tidyHtml: false,
        maxHeight: 120,
        convertVideoLinks: true,
        Token:$('meta[name="_token"]').attr('content'),
        imageUpload: '/admin/image/upload',
        formattingTags: ['div', 'span', 'p'],
        buttons: ['html', 'bold', 'italic', 'deleted', 'link', 'image', 'video'],
        initCallback: function() {},
        imageUploadCallback: function(image, json) { console.log(image, json); },
        imageUploadErrorCallback: function(json) { console.log(json); }
    });
    $('.editor_only_ul_min').redactor({
        lang: 'en',
        wym: true,
        focus: false,
        convertDivs: false,
        tidyHtml: false,
        maxHeight: 120,
        formattingTags: ['ul', 'li', 'a'],
        buttons: ['unorderedlist'],
        initCallback: function() {}
    });


});